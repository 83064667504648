// Don't get rid of an existing config that might be set on individual pages
window.lazySizesConfig = window.lazySizesConfig || {};

window.lazySizesConfig.preloadAfterLoad = true;

/*! modernizr 3.6.0 | MIT */
// This loads a webp image and checks for a successful load event
window.webpSupport = false;
var testImageWebp = new Image();
testImageWebp.onload = function() {
    webpSupport = (testImageWebp.width == 1);
};
testImageWebp.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';

var bgLoad, regBgUrlEscape;
regBgUrlEscape = /\(|\)|\s|'/;
bgLoad = function(url, cb) {
    var img = document.createElement('img');
    img.onload = function() {
        img.onload = null;
        img.onerror = null;
        img = null;
        cb();
    };
    img.onerror = img.onload;

    img.src = url;

    if (img && img.complete && img.onload) {
        img.onload();
    }
};

addEventListener('lazybeforeunveil', function(e) {
    if (e.detail.instance != lazySizes) { return; }

    var load, bg;
    if (!e.defaultPrevented) {

        var target = e.target;
        target.preload = 'auto';

        // handle data-background-src
        bg = target.getAttribute('data-background-src');
        if (bg) {
            //If the string contains a ?, use & instead of ?
            bg += ((new RegExp("\/.*\?.*$").test(bg)) ? "&" : "?");
            // If webp support is detected, serve webp
            bg += (webpSupport ? "format=webp&quality=65" : "format=jpg&quality=80")
                // Add image to queue
            e.detail.firesLoad = true;
            load = function() {
                target.style.backgroundImage = 'url(' + (regBgUrlEscape.test(bg) ? JSON.stringify(bg) : bg) + ')';
                e.detail.firesLoad = false;
                lazySizes.fire(target, '_lazyloaded', {}, true, true);
            };

            bgLoad(bg, load);
        }
    }
}, false);